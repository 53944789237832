import React from "react"
import Seo from "../../components/seo"
import Layout from "../../Layout"
import Breadcrumbs from "../../components/Breadcrumbs"

const OnlineOrdersPage = () => {
  return (
    <Layout>
      <Seo title="Online Orders" noIndex />
      <Breadcrumbs title={"Online Orders"} />
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8">Content Pending</div>
        </div>
      </div>
    </Layout>
  )
}

export default OnlineOrdersPage
